import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Building multiple blog post types on a Gatsby Website",
  "description": "How to create multiple blog post type in a Gatsby Blogging website",
  "date": "2020-08-05",
  "image": "gatsby-blog-type.jpg",
  "tags": ["gatsby", "frontend", "javascript"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Prerequisite`}</h2>
    <p>{`Before continuing on reading the blog, I would assume `}</p>
    <ul>
      <li parentName="ul">{`You know what Gatsby is`}</li>
      <li parentName="ul">{`You know how to use Gatsby in general`}</li>
      <li parentName="ul">{`You have read `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/tutorial/"
        }}>{`Gastby tutorials`}</a>{` and know how to set up a blogging site with Gatsby`}</li>
    </ul>
    <p>{`If you follow the tutorials on Gatsby site, you've created a nice blogging website. However, they only introduced one template for all blog posts.
In some cases, you might want to have different blog types for different content you write. I ran into this issue when I developed a documentation site with Gatsby for my company.
Therefore, I am going to write the solution that I've found so far.  `}</p>
    <h2>{`Starting point`}</h2>
    <p>{`We will start with a Gatsby starter blog by running. You might need to install gatsby-cli to be able to run gatsby command.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`gatsby new gatsby-starter-blog https://github.com/gatsbyjs/gatsby-starter-blog`}</code></pre></div>
    <p>{`To install dependencies, run`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`npm i`}</code></pre></div>
    <p>{`To start up the local development, run `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`npm start`}</code></pre></div>
    <p>{`Navigate to `}<a parentName="p" {...{
        "href": "http://localhost:8000/"
      }}>{`http://localhost:8000/`}</a>{`, you will see a blog site looking like this:`}</p>
    <div style={{
      "textAlign": "center",
      "margin": "0 auto",
      "marginBottom": "20px"
    }}>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1035px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b5beb795c7f5f391cc652d6984c2b8f1/7e318/starter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75.2895752895753%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABzElEQVQ4y6VUTW/aQBDlX/MTOMAJJA5wQYI/AFLaG4dKuUQcKoRaNWA7Dja2Y+z197f96tnUiERxoiYjPc3s7ng8M/tmO/gnVVXB932kaYowDOF5HsqyRBRFfJ/sIAiQZRnyPEebdBqDnBaLBcbjMSaTCXq9HobDIZbLJbrdLqbTKQaDAfr9PjabzSWJ1oCUwWg0wmw2w2q1wm63g2EYPOM4ji/Zky6K4uMMyenm23es12vM53Nst1t8RjrXi8hjKPKM9822bWiaDvN8BmMOmOPAsmw4tW5gWRaSJHk7YFWVuLv9AeVRhqIoUE4GZNOHVOPhHECxQ65ZECPP0ksrXl9Q56MSqiv8V8n0gZ9WcJISdlzCqVF9pYdlWWEnm/gpW/ilR/htRIiygv+KzogiDRrKEDPaM6wd9JMKQ1NhGhrX2un03M8aqqrieDxyaJrG94hWryn0oodxGCDwXAS+hzDwa855cF33ogk0QcRFumW6lPaS60okluNg5xBYgXsrR/GVHlLJiv4E3ay55kdwwxhJ+kwPAs1wo4l7BLLfDfgoP0ASBezv/0AUBMiyjMPhAEmSINTr/X4PURS5zRjjj8i7k9I28Nf7beeN/AUdA4E/DqLVUgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "starter",
              "title": "starter",
              "src": "/static/b5beb795c7f5f391cc652d6984c2b8f1/e3189/starter.png",
              "srcSet": ["/static/b5beb795c7f5f391cc652d6984c2b8f1/a2ead/starter.png 259w", "/static/b5beb795c7f5f391cc652d6984c2b8f1/6b9fd/starter.png 518w", "/static/b5beb795c7f5f391cc652d6984c2b8f1/e3189/starter.png 1035w", "/static/b5beb795c7f5f391cc652d6984c2b8f1/44d59/starter.png 1553w", "/static/b5beb795c7f5f391cc652d6984c2b8f1/7e318/starter.png 1892w"],
              "sizes": "(max-width: 1035px) 100vw, 1035px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <p>{`so, at this point, we only have one template for blog post, which is located at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/templates/blog-post`}</code>{`.
Let's say we want to have content which we want to use a different template. Let's call it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`project page`}</code>{`. If we want the Gatsby site to be able to consume different templates for various blog type. `}</p>
    <p>{`Here are what we need to do:  `}</p>
    <ul>
      <li parentName="ul">{`Create a new folder for different blog type`}</li>
      <li parentName="ul">{`Add source system file for new folder content in `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`gatsby-config`}</code>{` `}</li>
      <li parentName="ul">{`Create Project Page template`}</li>
      <li parentName="ul">{`Edit `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`gatsby-node.js`}</code>{` to apply different templates for some blog types inside `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`createPage`}</code>{` API`}</li>
    </ul>
    <h2>{`1. Create a new folder for different blog type`}</h2>
    <div style={{
      "textAlign": "center",
      "margin": "0 auto",
      "marginBottom": "20px"
    }}>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "760px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/89b22391dac86ef20a69236baac33d72/3c051/folder-structure.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "96.13899613899613%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACw0lEQVQ4y5WU22pTURCG8zZpEVqtxCb7lH3IPp9PObRJ2goKooi+gaJ4IUiLL2GhQgUf8PdfK4eLGiFefMzK6qy/M7NnphMkGdr5Jc4WF5gvr1A2Y0zO5mgmMzLl/RJuEGHkh/DCWJ79KJZnxwu2f9vQ0YYWoqxEc34BL1IQJKcwRxZ0a0QcGDat6UCln2baEn1ttwxXVjFMdMQjP85QzRZIy5wRVvI/97WhFBFOqmHJ85aHv9f0NQMd8UCIxnnFKC9Rj2cybRHZJqpdj3cxWAlaUjBMczSsZcva+XGKgT6UKLq5p6i9EhQHcSEiSsoGLWspIhQfKykqBBQX6cvUpe+/GdCvo+kqVF2BMdSQ5ikm8znyjGKkKEvaFHmRwvVYAk2BbqgQbx4i7hVVQac7u8XBmsPzOxxd3mHw+hbh+99w3/6C/eYeT67u8Wh+h+70h/TrPuCA992znzgsr9E5zj/iKPuAY9KffoV+dQP1+RckL69RvPqO6MUN9Pk3nFSfV370/xveF5/wOHzHttF6GBqnCH0LZZ2haXJURYIwHMH3bXiuiThyYFsKNLUHXXu2E4OoSk98FDYtG9f2ItncY1K3E6RFA9Px+ZVZbJ39KApvOruRGiP6sbEHbJs+D6eqwfZxEZZTxNMFgrKAE8dQHQrSR4hKjN0ohi01Op5rMT2PXzNhehqc0II5qxFWCsUNRKULP+A0eTZL4MBlCXYhdCyLgnFoo2XdlldL1u8ETX2CMn2KMu8hT/vIYp82QJb6yBKftTZ3EgUmRg4FNXM1y+VszmnRWMs+U3CYnkCka8rabFBkejtgjeUsG+uxaxdcXfUUhuVx+MVUrND2nGNtM8tiFbncbVnLpTCeIq9qOYZybVnO3othuxzkihJLgNavG0bZIqctaL0okcvhvwXFfjMcF3krIlyJJXnJReutl8L+gn8AJUq1IxTW5xsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "folder-structure",
              "title": "folder-structure",
              "src": "/static/89b22391dac86ef20a69236baac33d72/3c051/folder-structure.png",
              "srcSet": ["/static/89b22391dac86ef20a69236baac33d72/a2ead/folder-structure.png 259w", "/static/89b22391dac86ef20a69236baac33d72/6b9fd/folder-structure.png 518w", "/static/89b22391dac86ef20a69236baac33d72/3c051/folder-structure.png 760w"],
              "sizes": "(max-width: 760px) 100vw, 760px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <p>{`First, we need to add a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`projects`}</code>{` folder and add a new post to it. The post should contain two required a title, date, and description which Gatsby will query as frontmatter.
Importantly, we need to add one more field `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type`}</code>{` to distinguish different type of post, which we will use to tell Gatsby which template to use during build time`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`---
title: My First Project Post!
date: "2020-05-06T23:46:37.121Z"
description: 'This is my first project post'
post: project
---`}</code></pre></div>
    <h2>{`2. Add source system file for new folder content in `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`gatsby-config.js`}</code></h2>
    <p>{`Next, we need to tell Gatsby to source the files the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`project`}</code>{` folder into the application, as the same way we do with the blog content`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
    resolve: \`gatsby-source-filesystem\`,
    options: {
       path: \`\${__dirname}/content/projects\`,
        name: \`projects\`,
    },
}`}</code></pre></div>
    <h2>{`3. Create Project Page template`}</h2>
    <p>{`Now we will create our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`project-post`}</code>{` template so that we can use it in Gatsby `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPages`}</code>{`. We can just copy everything from the blog-post and rename the query `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BlogPostBySlug`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ProjectPostBySlug`}</code>{`.
Let's name the component `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ProjectPostTemplate`}</code>{`. We should add a small text at the beginning to differentiate it with BlogPost. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`ProjectPostTemplate`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` pageContext`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` location `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` post `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`markdownRemark
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` siteTitle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`site`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`siteMetadata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` previous`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` next `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pageContext

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Layout location`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`location`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`siteTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`SEO`}</span>{`
        title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        description`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`frontmatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`description `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`excerpt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`article`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`bold`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`This is the project page`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`bold`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// other lines of code`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// other lines of code`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`article`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// other lines of code`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Layout`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`4. - Edit `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`gatsby-node.js`}</code>{` to apply different templates for some blog types inside `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`createPage`}</code>{` API`}</h2>
    <p>{`During the creating pages step, we query all the markdown to get all the nodes, each of which contains data about each blog post. We will iterate through each node and create a page with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPage`}</code>{` API.
To make it work for project page template, first, we need to import the template, we can put it under the blog post import`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` blogPost `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`./src/templates/blog-post.js`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` projectPost `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` path`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token template-string"
            }}><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`./src/templates/project-post.js`}</span><span parentName="span" {...{
                "className": "token template-punctuation string"
              }}>{`\``}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span></code></pre></div>
    <p>{`We also need to add to GraphQL query the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type`}</code>{` so that we can use later in our iteration`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`graphql`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
      {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
`}<span parentName="span" {...{
                "className": "gatsby-highlight-code-line"
              }}>{`                type`}</span>{`              }
            }
          }
        }
      }
    `}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now while we are looping through each node, we will have a condition based on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type`}</code>{` to tell Gatsby which template it should use.
If the type is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`project`}</code>{`, we will pass `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`projectPost`}</code>{` as the value for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`component`}</code>{` in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPage`}</code>{`. Otherwise, the default blog type is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`blogPost`}</code>{` `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` previous `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`node
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` next `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`index `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`node

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`node`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`frontmatter`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`type `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`===`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'project'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token function"
            }}>{`createPage`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        path`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`node`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`slug`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        component`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` projectPost`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        context`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          slug`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`node`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`slug`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          previous`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          next`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`else`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token function"
            }}>{`createPage`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        path`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`node`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`slug`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        component`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` blogPost`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        context`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          slug`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` post`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`node`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`slug`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          previous`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          next`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`At this step, the main page should have our project post(s).`}</p>
    <div style={{
      "textAlign": "center",
      "margin": "0 auto",
      "marginBottom": "20px"
    }}>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1035px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d4c264b17519df38eddd49e26e5499cd/07a9c/main-page.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "91.8918918918919%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACIElEQVQ4y61Uy27aQBTNl6YSEt/ABoQAIbFgwwdk1TVdIRaAhFSpoiACgYJ5xaQm5hGeNvg9tk9nBkjSbipIrnR853F95nHvmRtQKxQKSCQSiEQiiEajiMfjCAaDyGQyKJfLCAQCCIfDSKfTSCaTiMViHNPplP0O3/dxthv2kWUZxWIRpVIJnU4HjUYDlUoF+Xwe6/UarVYLzWYTtVoNkiTx+Xq9Dtd18a9xwmw2iy+3twiFQnx3qVQK1xonXK3WGI5GEEUR1WoVuVzuY4SAB2Jqf00Yuk5xALEtuI518jb3xLZhmiaHZVnQaSzDK6HQbuDb1zvIzxM06F216Z11hmNUu2PcDyQ8PE7RepQhPM3RHMkYThbYbDZ4WS6xPGE2m8HzvCOhQVdR9hoIIbDZ6oYBk/g4EGDvHHE4gbVN979H/jzjhLZLd2N7MOiuNOo124XueFAtF5brX074e2vhu6jg10xHe2Hhfmqit7JRe9YxVZ3LCXfbDeSJhJfFHIZ2oNnVqNfgu851R3ZoOexVBdphD1XZ8QW2NIvnUric0KOZdo/Zs7yjJ/4HkiJtTfx8UvBjrKI60Tke5ub1hOyYy8UMym7La5CpgR33rAY2xvpMFQar0ZNC3veZf9MyfVHYKyOKY3S7XQi9HnoU/X6fQxAEDjbG4lgMaw8GAz7OYlRVfSNkkmEKYSDE5Yo54zhG+FPlOM5rm+Ec8/4Z+3Sl/AE6ZFi1A2Cm4AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "main-page",
              "title": "main-page",
              "src": "/static/d4c264b17519df38eddd49e26e5499cd/e3189/main-page.png",
              "srcSet": ["/static/d4c264b17519df38eddd49e26e5499cd/a2ead/main-page.png 259w", "/static/d4c264b17519df38eddd49e26e5499cd/6b9fd/main-page.png 518w", "/static/d4c264b17519df38eddd49e26e5499cd/e3189/main-page.png 1035w", "/static/d4c264b17519df38eddd49e26e5499cd/07a9c/main-page.png 1440w"],
              "sizes": "(max-width: 1035px) 100vw, 1035px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <p>{`If we click on the first post, we will see the project template is used correctly.`}</p>
    <div style={{
      "textAlign": "center",
      "margin": "0 auto",
      "marginBottom": "20px"
    }}>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1035px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0837d7e21417c53c67a7d76bff34760c/3d405/project-page.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "91.8918918918919%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB8klEQVQ4y71Ta28SQRTdnw9JTfwiIbjwwcVUqrUfijV8qVIaoZoWKouL2A1tt7DsY5alvPZ9nJk+jEYTkcZJTs6dyeTcc++dEdyBhlZ1H6cH76E0PtD4HXqfjtCuVXF0WEOcJFhlCZ6UAXm7g3H5Jdy9bZDdLVh7r4HdTeBLk19KoujvBYPWR3idJoJOC3bjgPNCbmLeqALm8KfLvu/fx9Efkgjd/jkeP8lgv3aIzFMRz16UsFN+g+KrbTwvbeHRxgbEfB7lchmlUgnZbBa5XA71ev3G/S8tEVzHQfPkBB25DWLbsC0TDuX5dAqHEJypKgzDgOu6WC6XME0TlmXx+LcO8cBLYJkc6pJQN7PZjGdnzFzZ1OlisVhNUJZliKKIQqEASZKQSqVQLBaRp31Lp9OoVCqrCcZxjCAI+NQ8z0MYhpzZ+T+VzErSdZ022+Blsng0GkHTNC68suCDD8WbT6G2j3E9calLiw/CMEYYDgYcDrFvQTB2CGcGcgt2n4FVxqoV9KtLSGIWQ8pfez10FQXf1D7aFxaOz3Q0+xY+XxDIlzaUK4LTcxuqPsZiPsOUvlUG9iomkwlvES/5rv1sEAwRRUj3Af0EfnwDFvM9Rfhfe8j/I3OX/MBagn6UQHMDdM0lFIqe5SFYQ/Xe4R2vaRDfAYXmYIgNWqKtAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "project-page",
              "title": "project-page",
              "src": "/static/0837d7e21417c53c67a7d76bff34760c/e3189/project-page.png",
              "srcSet": ["/static/0837d7e21417c53c67a7d76bff34760c/a2ead/project-page.png 259w", "/static/0837d7e21417c53c67a7d76bff34760c/6b9fd/project-page.png 518w", "/static/0837d7e21417c53c67a7d76bff34760c/e3189/project-page.png 1035w", "/static/0837d7e21417c53c67a7d76bff34760c/3d405/project-page.png 1348w"],
              "sizes": "(max-width: 1035px) 100vw, 1035px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>
    <p>{`That is the basic way of using different templates in Gatsby. `}</p>
    <p>{`In some use cases, you may not want to query all the markdown. You can use GraphQL filter to only query the markdown content in a specific path by using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`absolutePath`}</code>{`. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` listQuery `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` graphql`}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`
  query ListQuery {
    blog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {fileAbsolutePath: {regex: "/blog/*.\\\\md$"}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            description
          }
        }
      }
    }
  }
  project: allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: {fileAbsolutePath: {regex: "/project/*.\\\\md$"}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        excerpt(pruneLength: 200)
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
          description
        }
      }
    }
  }
}
`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span></code></pre></div>
    <p>{`In this example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`blog`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`project`}</code>{` are aliases, so you can use them in your component as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`props.data.blog`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`props.data.projects`}</code></p>
    <p>{`You can find the full repo `}<a parentName="p" {...{
        "href": "https://github.com/dragonza/gatsby-mutiple-blog-post-types"
      }}>{`here`}</a></p>
    <p>{`That's it for today! Thanks for reading. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      